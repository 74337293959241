.text-center-about {
  text-align: left;
}
p.text-center {
  text-align: center !important;
  font-size: 1.5vw;
  color: #fff;
}
.Header-bg-style .container .row {
  padding: 40px 0px 0px;
}
.form_style h3,
.salery_range {
  font-size: 22px;
  font-weight: 600;
  text-decoration: none;
}

.form_style .d-flex .form-group,
.update_form .d-flex .form-group {
  position: relative;
}
.form_style .d-flex .form-group span.text-warning,
.update_form .d-flex .form-group .text-warning {
  position: absolute;
  right: 0px;
  bottom: 9px;
  color: #ff0000b5 !important;
  margin-bottom: -22px;
  font-size: 12px;
}

.update_form .d-flex .form-group .text-warning {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #ff0000b5 !important;
  margin-bottom: -22px;
  font-size: 10px;
}
.form_style .d-flex .form-group input:focus {
  border: red;
}

.form_style .d-flex .form-group .form-check-label {
  line-height: 2em;
  padding: 5px 10px;
  padding-left: 0px;
}
@media (min-width: 992px) {
  .form_style .d-flex .form-group {
    display: flex;
    align-items: center;
  }
  .form_style .d-flex .form-group label {
    padding-right: 20px;
    width: 90%;
    padding-top: 15px !important;
  }
  .form_style .d-flex .form-group input {
    /* width: 50%; */
  }

  .form_style .d-flex .form-group .form-check-label {
    line-height: 2em;
    padding: 5px 10px;
    padding-left: 0px;
  }
}

/*Mobile Form*/
@media (max-width: 576px) {
  .form_style .d-flex {
    display: block !important;
  }
  .form_style .d-flex .form-group {
    width: 100%;
  }
  button.btn.btn-primary {
    width: 80% !important;
  }
  .form_style .d-flex .form-group .form-check-label {
    line-height: 2em;
    padding: 5px 10px;
  }
}
button.exlpore_btn {
  font-size: 16px;
  outline: none;
  border-radius: 1.5vw;
  padding: none;
  border: 1px solid #2196f3 !important;
  padding: 10px 30px;
  border-radius: 4px;
  margin-top: 3vh;

  background-color: #2196f3 !important;
  /* box-shadow: 0 0 10px #2196f3, 0 0 40px #2196f3, 0 0 80px #2196f3; */
}
button.exlpore_btn a {
  text-decoration: none;
  font-size: 16px !important;
}
.hero_image img {
  width: 60%;
}
.header_div {
  background-color: #020202;
  min-width: 100%;
}

a {
  outline: none;
  text-decoration: none;
  color: #fff !important;
  font-size: 1.5vw !important;
  font-weight: 400 !important;
}

ul.nav_links {
  list-style: none;
  display: flex;
  justify-content: center;
  border-radius: 3vw;
  /* background: black; */
}
ul.nav_links {
  padding-left: 0px;
  overflow: hidden;
  border-radius: none;
  border-radius: 9px 9px 0 0 !important;
  margin-top: -100px;
}

.navbar a {
  /* font-size: 1.3rem; */
  text-transform: uppercase !important;
  color: #fff !important;
}

.menu_active {
  font-weight: 600;
  /* border-bottom: 1px solid white; */
}

.menu_active {
  font-weight: 400 !important;
  color: white !important;
  background-color: #eaeeff !important;
  border-bottom: 2px solid #8ba1ff;
}
.icon-image {
  width: 40px;
  display: block;
}

li.nav-item {
  background-color: #fff;
  color: #000 !important;
  width: 25%;
  /* border-bottom: 2px solid #8ba1ff; */
}
li.nav-item a {
  background-color: #fff;
  color: #000 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 15px 40px;
  /* border-bottom: 2px solid #fff; */
}
ul.nav_links {
  /* border-radius: 100px !important; */
}

.container.nav-div {
  margin-top: 5vh;
  line-height: 4vh;
  margin-bottom: 20px;
}

a.nav-link.active.menu_active {
  font-size: 1.5vw;
}

h2.about {
  font-size: 3vw;
  color: #fff;
  font-weight: 700;
}
.Header-bg-style h3.h3_sub_heading {
  font-size: 16px;
  color: #fff;
  font-weight: 300;
}
.ct {
  color: #4c6dff;
  font-size: 72px;
  text-align: left;
  line-height: 82px;
  font-weight: 800;
  padding-bottom: 10px;
  font-size: 82px;
  background: -webkit-linear-gradient(45deg, #1976d2, #03a9f4 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
}
p.bt {
  color: #fff;
  font-size: 22px;
}
@media (max-width: 992px) {
  .ct {
    font-size: 62px;
    line-height: 72px;
  }
  p.bt {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .ct {
    font-size: 52px;
    line-height: 62px;
  }
  p.bt {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .ct {
    font-size: 42px;
    line-height: 52px;
  }
  p.bt {
    font-size: 16px;
  }
}

.my-row {
  height: 100px;
  background-color: blanchedalmond;
  border: 1px solid;
}
.something {
  margin-bottom: 30px;
}
.Header-bg {
  background-image: url(./images/bg.jpg);
  margin-bottom: 40px;
}
.Header-bg-style {
  background-color: #02000ae3;
  padding-top: 36px;
  text-align: left;
  /* background-image: url(./images/bg.jpg); */
}
.Header-bg-style .container {
  padding-bottom: 52px;
}
.bt {
  font-size: medium;
}
.form-content {
  margin-bottom: 50px;
}
.form-content h4 {
  padding-top: 20px;
  padding-bottom: 10px;
}
.form-imege {
  width: 130px;
  margin-bottom: 15px;
}
.form-group {
  line-height: 2;
  width: 45%;
}

input.form-control {
  border: 1px solid #4c6dff21;
  outline: none;
  border-radius: 4px;
  box-shadow: 3px 3px 5px 2px #00000000;
}

input.form-control:active {
  outline: none;
  border-bottom: 2px solid red;
}
input#exampleInputPassword1 {
  /* background-color: #fafafa; */
}

label {
  margin-top: 0vh;
  font-weight: 500;
  /* padding-left: 2vw; */
  /* min-width: 200%; */
  text-transform: capitalize;
  padding-bottom: 10px;
}
form .justify-content-between {
  margin-bottom: 5px;
}
.col-4 form .justify-content-between {
  margin-bottom: 25px;
  margin-top: -8px;
}
.col-4 button.btn.btn-primary {
  margin-top: 0;
}
.table_wrapper {
  /* box-shadow: rgb(33 150 243 / 9%) 0px 8px 24px; */
  padding: 0px 23px 25px;
  /* border: 1px solid #0000ff17; */
  border-radius: 3px;
}
p.text-center {
  text-align: center !important;
  color: #000;
  font-size: 14px !important;
  margin-bottom: 0px;
}
td.td_of_charts {
  font-weight: 600;
}
form {
  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; */
  border-radius: 1vw;
  padding-bottom: 1vh;
}
input.form-control:active {
  border-bottom: #4c6dff;
}
.col-lg-4.col-11 {
  /* box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; */
  border-radius: -100vw;
  margin-left: 0vw;
}
.table_form input {
  border: 1px solid #4c6dff21;
  outline: none;
  border-radius: 4px;
  box-shadow: 3px 3px 5px 2px #00000005;
  /* border-bottom: 2px solid #5c6bc069; */
  text-align: center;
  padding: 7px 0px;
}
.form-group {
  /* margin-right: 12vw; */
}
input#exampleInputPassword1 {
  width: 100%;
}
.form-check {
  /* padding-left: 1.5em; */
  padding-left: 0px !important;
}
.form-check label {
  padding-left: 10px;
}
div#root {
  background-color: #f1f1f1;
}

button.btn.btn-primary {
  /* margin-left: 2vw; */
  /* margin-top: 2vh;
  color: rgb(255, 255, 255);
  background: #1de9b6;
  border: #1de9b6; */
}

th {
  text-transform: capitalize;
}
p.footerDetails {
  font-weight: 600;
  display: flex;
  justify-content: center;
  margin-top: 4vh;
  margin-bottom: 2vh;
}

.title_name {
  /* display: inline-block; */
  position: relative;
  left: 0%;
  margin-top: -5vh;
  padding: 0 2vw;
  font-size: 3.5vw;
  border-radius: 6vw;
  font-weight: 600;
  color: rgb(58, 55, 55);
  max-width: 100%;
  text-shadow: 0 0.03vw 0 #ccc, 0 0.06vw 0 #c9c9c9, 0 0.09vw 0 #bbb,
    0 0.12vw 0 #b9b9b9, 0 0.15vw 0 #aaa, 0 0.24vw 0.1vw rgba(0, 0, 0, 0.1),
    0 0 0.1vw rgba(0, 0, 0, 0.1), 0 0.05vw 0.05vw rgba(0, 0, 0, 0.3),
    0 0.05vw 0.05vw rgba(0, 0, 0, 0.2), 0 0.02vw 0.05vw rgba(0, 0, 0, 0.25),
    0 0.05vw 0.05vw rgba(0, 0, 0, 0.2), 0 0.01vw 0.01vw rgba(0, 0, 0, 0.15);
}

.table-responsive > .border-dark {
  border-color: #21252915 !important;
}
p.text-center {
  text-align: center !important;
  color: #000;
  font-size: 16px;
}
p.All_Headings {
  font-size: 24px;
  font-weight: 600;
  padding: 0px 0;
}

.row .bg-secondary {
  background-color: #4c6dff !important;
  color: #fff;
  font-size: 16px;
  font-weight: 600 !important;
  border-radius: 4px;
  padding: 14px 15px !important;
}

button.btn.btn-primary {
  /* margin-left: 2vw; */
  margin-top: 2vh;
  margin-left: 0px;
  color: rgb(255, 255, 255);
  background: #4c6dff;
  border: #4c6dff;
  width: 200px;
  margin-top: 34px;
  padding: 12px 0;
  margin: 0px auto 0;
  display: block;
}
.download_report {
  color: rgb(0, 0, 0);
  background: rgba(255, 255, 255, 0);
  border: #4c6dff;
  width: 210px !important;
  text-align: center;
  margin-top: 20px;
  padding: 12px 0;
  margin: inherit !important;
  display: block;
  border-radius: 3px;
  float: right !important;
}
.download_report span {
  padding-right: 10px;
}
.create_report {
  background-color: #4c6dffed;
  font-size: 16px !important;
  display: block;
  text-align: center;
  margin: 0 auto;
  width: 250px;
  padding: 7px 0px;
  text-decoration: none;
  margin-top: 20px;
  border-radius: 4px;
  font-weight: 400;
  border: navajowhite;
  color: #fff;
}
.investment_allowance .table td {
  padding: 15px 0px;
}
.investment_allowance .table input {
  background-color: #fafafa;
  border: none;
  outline: none;
  border-bottom: 2px solid #5c6bc0;
  border-radius: 0;
}
table.table.table-hover.table-bordered.border-dark th {
  background-color: #0c0c0c17;
}
.col-lg-8 .table_wrapper {
  display: none;
}
.report_header {
  padding-top: 100px;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 20px;
  text-align: center;
  margin-bottom: 36px;
}
.container-fluid.footer_div {
  background-color: #000000eb;
  color: #fff;
  /* padding: 1px 0; */
}

p.footerDetails {
  font-weight: 400;
  display: block;
  text-align: center;
  margin-top: 61px;
  margin-bottom: 0;
  padding: 17px 0;
}
.update_form {
  border: 1px solid #afafaf33;
  box-shadow: 0px 0px 9px 2px #00000014;
  padding: 15px 21px;
  margin-top: -3px;
  background-color: #fff;
}
@media only screen and (max-width: 992px) {
  .col-lg-4.col-11 {
    margin-left: 0vw;
    margin-top: 5vh;
  }
  .col-lg-8 .table_wrapper {
    display: block;
  }
  .col-lg-4 .table_wrapper {
    display: none;
  }
}

.padding_top_30 {
  padding-top: 30px;
  padding-left: 12px;
  padding-right: 12px;
}

@media only screen and (max-width: 768px) {
  .hero_image img {
    display: none !important;
  }
  ul.nav_links {
    display: block;
    /* background: black; */
    margin-top: 0px;
  }
  li.nav-item {
    background-color: #fff;
    color: #000 !important;
    width: 100%;
    /* border-bottom: 2px solid #8ba1ff; */
  }
  .form_class .d-flex {
    display: block !important;
  }
  .form_class .d-flex .form-group {
    width: 100%;
  }
  .form_class .d-flex .form-group input {
    width: 100% !important;
  }
}

.accordionContent {
  height: 0;
  opacity: 0;
  display: flex;
  overflow: hidden;
  transition: all 300ms ease-in-out;
}
.accordionContent.show {
  height: 450px;
  opacity: 1;
  margin-top: 30px;
}

.accordion {
  cursor: pointer;
  padding-left: 0px !important;
}
.accordion_tab {
  border: 1px solid #00000000;
  box-shadow: 0px 0px 2px 2px #00000008;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 6px 35px;
}
.accordion .All_Headings {
  padding-top: 0px;
  margin-bottom: 0;
}
.update_form {
  border: 1px solid #00000000;
  box-shadow: 0px 0px 2px 2px #00000000;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px 35px;
}
.update_form h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
.form-check input.form-control {
  /* width: 75%; */
}

.update_form label {
  font-size: 14px;
}

.update_formform .justify-content-between {
  margin-bottom: 20px;
}
@media (max-width: 992px) {
  .update_form {
    padding-left: 10px;
    padding-right: 5px;
  }
  .update_form .justify-content-between {
    margin-bottom: 0px;
  }
  .update_form .d-flex {
    display: block !important;
  }
  .update_form .d-flex .form-group {
    width: 100%;
  }

  .update_form .d-flex .form-group .form-check-label {
    line-height: 2em;
    padding: 5px 10px;
  }
  #root .update_form .form-group label {
    padding-top: 20px;
    padding-bottom: 0px !important;
    line-height: 15px !important;
  }
  #root .update_form .form-group input {
    font-size: 12px;
  }
  #root .update_form .select_field {
    font-size: 12px;
  }

  .update_form .form-check.yearly_tax {
    margin-left: 20px;
  }
  .update_form .form-check label {
    padding-left: 0px;
  }
  .update_form .justify-content-between {
    margin-bottom: 20px !important;
  }
  .update_form .d-flex .form-group {
    margin-bottom: 10px !important;
  }
}
table {
  font-size: 13px;
}
.update_form .form-check input.form-control {
  width: 100%;
}
.update_form .justify-content-between {
  margin-bottom: 20px;
}
.user_information h3 {
  text-align: center;
  text-decoration: underline;
  margin-bottom: 30px;
}

.accordion-button.togolBtn::after {
  transform: rotate(-360deg) !important;
}
.table-responsive {
  /* overflow-x: hidden !important;           */
}
.accordion-body {
  padding: 0;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0rem rgb(13 110 253 / 0%) !important;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0rem rgb(13 110 253 / 0%) !important;
}
button.accordion-button.togolBtn {
  background-color: #fff;
}
.accordion > div {
  margin-bottom: 10px;
}
.accordion-header button {
  position: relative;
}

.accordion-header button span {
  /* background-color: red; */
  text-align: right;
  float: right;
  display: inline-block;
  right: 50px;
  position: absolute;
}
.accordion-body {
  padding: 0rem 0rem !important;
}

.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 0px;
}
.form-control {
  font-size: 14px !important;
}
.accordion-button:not(.collapsed) {
  background-color: #fff !important;
  color: #000 !important;
}
.total_calc tr {
  border-color: #e4e4e4;
}

.all_options_div label {
  font-size: 14px;
}
.all_options_div input {
  font-size: 12px !important;
}
.col-md-8 .table_wrapper {
  display: none;
}
@media (max-width: 992px) {
  .col-md-8 .table_wrapper {
    display: block;
  }
  .col-md-4 .table_wrapper {
    display: none;
  }
}

.update_form label {
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.row.report_page {
  /* border: 1px solid rgba(221, 221, 221, 0.697); */
  display: block !important;
  /* box-shadow: 0px 0px 23px 1px #00000008; */
  padding-left: 104px;
  padding-right: 84px;
  padding-bottom: 0px;
}
.update_form button.btn.btn-primary {
  width: 120px;
  margin: 0px auto;
  padding: 8px 0;
  font-weight: 500;
}

.report_page .All_Headings {
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 20px;
}

#taxableTable {
  font-size: 12px;
}
@media (max-width: 768px) {
  .userForm .d-flex {
    display: block !important;
  }
  .userForm .d-flex .form-group {
    width: 100% !important;
  }
  .userForm .d-flex .form-group label {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  .user_information .All_Headings {
    padding-bottom: 30px;
  }
}

/* .taxRule{
  position: absolute;
  height: auto;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  inset:40% 20% 40% 35%;
  background-color:crimson;
  padding: 20px;
  border: #fff;
  border-radius: 10px 2px 20px 5px;
  box-shadow: 100px;
 */
@media only screen and (max-width: 992px) {
  .form_style .d-flex .form-group span.text-warning,
  .update_form .d-flex .form-group .text-warning {
    position: absolute !important;
    right: 0px !important;
    bottom: 0px !important;
    color: #ff0000b5 !important;
    margin-bottom: -22px !important;
    font-size: 12px !important;
  }
  form .justify-content-between {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 992px) {
  form .justify-content-between .form-group {
    margin-bottom: 20px !important;
  }
}

.form-control {
  border: 1px solid #e4e8fb;
}

.form_style .form-check {
  margin-left: 19px;
}
.form_style .form-check .form-check-input {
  margin-top: 7px;
}

.submit_btn {
  margin-top: 40px !important;
}
table.table.table-hover.table-bordered.border-dark th {
  background-color: #0c0c0c00;
}
/* .total_calc thead tr{
  background-color: #0c0c0c17;
} */

table.table.table-hover.table-bordered.border-dark {
  margin-bottom: 0px;
}

div#collapseThree {
  margin-bottom: -17px;
}

.accordion-item:not(:first-of-type) {
  border-top: 1px solid;
  border-color: rgb(0 0 0 / 13%);
}
.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0) !important;
  border-radius: 3px;
  border-radius: 10px !important;
  box-sizing: border-box;
  overflow: hidden;
}
@media only screen and (max-width: 576px) {
  .accordion-button {
    font-size: 12px !important;
    padding: 1rem 0.25rem;
  }
}

.tax_rules {
  margin: 0 !important;
  width: 200px !important;
}
.button_container {
  display: block !important;
}
.backBtn {
  float: left;
  color: rgb(0, 0, 0);
  background: rgba(255, 255, 255, 0) !important;
  border: #4c6dff;
  width: 210px !important;
  text-align: center;
  margin-top: 20px;
  padding: 12px 0;
  margin: inherit !important;
  display: block;
  border-radius: 3px;
}
.backBtn span {
  padding-left: 10px;
}
.table > :not(:first-child) {
  border-top: 1px solid currentColor;
}

.report_page .table-responsive {
  padding-bottom: 30px !important;
}
.form-check.yearly_tax {
  margin-left: 23px;
}
.update_form .form-check label {
  padding-left: 0px;
}
.accordion-button {
  font-weight: 600;
}
th.text-center {
  text-align: left !important;
}
tbody tr td p.text-center {
  text-align: left !important;
}
.border_color {
  border-bottom: 1px solid #f7f7f7;
  width: 122%;
  margin: -22px -11% 22px;
}
.table-hover > tbody > tr {
  --bs-table-accent-bg: #fff !important;
}
.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: #f7f7f748 !important;
}
tbody tr td {
  border-left: -1px solid;
}
@media only screen and (max-width: 767px) {
  .row.report_page {
    /* border: 1px solid rgba(221, 221, 221, 0.697); */
    display: block !important;
    /* box-shadow: 0px 0px 23px 1px #00000008; */
    padding-left: 34px;
    padding-right: 84px;
    padding-bottom: 34px;
  }
}
@media only screen and (max-width: 776px) {
  .backBtn,
  .download_report {
    float: none !important;
    margin: 0 auto !important;
    display: block !important;
  }
}

.salary_details {
  display: flex;
  align-items: center;
}
.salary_details h3 {
  margin-bottom: 0px;
}
.taxRule {
  padding-top: 30px;
}
.salery_range {
  margin-bottom: 15px;
}
.row.report_page {
  /* border: 1px solid rgba(221, 221, 221, 0.697); */
  display: block !important;
  padding-bottom: 0px;
}
.row.report_page .table > thead {
  vertical-align: bottom;
  background-color: #ededed;
}
